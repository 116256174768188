<template>
  <div id="cloud-listing-layout">

    <b-button variant="primary" @click="goBack"> Επιστροφή</b-button>

    <cloud-listing-preview :listing-id="$route.params.listingId" class="mt-4"></cloud-listing-preview>

  </div>

</template>


<script>
import CloudListingPreview from "@components/cloud/preview/CloudListingPreview";

export default{

  components: {CloudListingPreview},

  props:{

  },
  data(){
    return{

    }
  },

  methods: {
    goBack() {
     this.$router.go(-1)
    }
  }

}
</script>
